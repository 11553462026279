import React from "react";
import CustomerCare from "../../components/CustomerCare"
import {graphql} from "gatsby";
import Layout from "../../components/Layout";
import {connect} from "react-redux";
import SEO from "../../components/SEO";

const FAQs = (props) => {
    return (
        <Layout>
            <SEO {...(props?.data?.seo?.frontmatter || {})}/>
            <CustomerCare {...props} fields={props?.data}/>
        </Layout>
    )
};
const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(FAQs);

export const query = graphql`
fragment FAQFragment on MarkdownRemark {
     frontmatter {
      title
      content{
        title
        queAns{
            question
            answer
        }
      }
    }
  }
  query FaqPageQuery {    
  seo: markdownRemark(frontmatter: {templateKey: {eq: "faq-seo"}}) {
        frontmatter {
            title
            description
        }
  }
  enFaq: markdownRemark(frontmatter: {templateKey: {eq: "en-faq"}}) {
    ...FAQFragment
  }
  deFaq: markdownRemark(frontmatter: {templateKey: {eq: "de-faq"}}) {
    ...FAQFragment
  }
  esFaq: markdownRemark(frontmatter: {templateKey: {eq: "es-faq"}}) {
    ...FAQFragment
  }
  frFaq: markdownRemark(frontmatter: {templateKey: {eq: "fr-faq"}}) {
    ...FAQFragment
  }
  heFaq: markdownRemark(frontmatter: {templateKey: {eq: "he-faq"}}) {
    ...FAQFragment
  }
  itFaq: markdownRemark(frontmatter: {templateKey: {eq: "it-faq"}}) {
    ...FAQFragment
  }
  jaFaq: markdownRemark(frontmatter: {templateKey: {eq: "ja-faq"}}) {
    ...FAQFragment
  }
  zhFaq: markdownRemark(frontmatter: {templateKey: {eq: "zh-faq"}}) {
    ...FAQFragment
  }
 }
`;